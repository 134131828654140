<template>
  <div class="home">
    <div class="home-menu">
      <div class="home-menu-left">
        <a-menu mode="horizontal" theme="dark" :selectable="false" :subMenuOpenDelay="1">
          <a-menu-item key="home">
            <img src="/img/logo.png" class="logo"/>
          </a-menu-item>
          <a-menu-item key="buildings">
            <router-link to="/home/dashboard" exact>
              <a-icon type="bank"/>Ēkas
            </router-link>
          </a-menu-item>
          <a-menu-item key="measurements">
            <router-link to="/home/measurements" exact>
              <a-icon type="read"/>Kā lasīt mērījumus?
            </router-link>
          </a-menu-item>
          <a-menu-item key="recommendations">
            <router-link to="/home/recommendations" exact>
              <a-icon type="setting"/>Ieteikumi gaisa kvalitātes uzlabošanai
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="home-container" v-if="loaded">
      <router-tab page-transition="" tab-transition="" :keep-last-tab="false" language="en" default-page="/home/dashboard"
                  :max-alive="20"
                  style="flex: 1;"/>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      loaded: false,
    };
  },
  async created() {
    this.loaded = true;
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-container {
  overflow: auto;
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
}

.home-menu {
  background: $primary-color;
  border-bottom: solid 1px #3e3e40;
}

.logo {
  height: 35px;
  margin-bottom: 0.5rem;
}

.sub-menu-pos {
  top: 44px !important;
}

.home-menu-left {
  float: left;
}

</style>
